const srdAttribution = {
    'finance-fr': {
        meeting: 'https://meetings.salesloft.com/sweep/discovery-call-finance',
        countries: true,
        industries: [
            'Banks',
            'Finance',
            'Financials - Banks',
            'Financials - Diversified Financials',
            'Financials - Investment',
            'Financials - Payment solution',
            'Government banks',
            'Investment'
        ]
    },
    'corporate-fr': {
        meeting: 'https://meetings.salesloft.com/sweep/discovery-call',
        countries: [
            'Austria',
            'Belgium',
            'Bulgaria',
            'Croatia',
            'Republic of Cyprus',
            'Czech Republic',
            'Estonia',
            'France',
            'Germany',
            'Greece',
            'Hungary',
            'Italy',
            'Latvia',
            'Lithuania',
            'Luxembourg',
            'Malta',
            'Netherlands',
            'Poland',
            'Portugal',
            'Romania',
            'Slovakia',
            'Slovenia',
            'Spain'
        ],
        industries: true
    },
    'corporate-uk': {
        meeting: 'https://meetings.salesloft.com/sweep/discovery-call-sdr-uk',
        industries: true,
        countries: true
    },
    'partner-sales': {
        meeting: 'https://meetings.salesloft.com/sweep/jeromeblum'
    }
};

export function getSalesLoftMeetings(country?: string, industry?: string, contactRequest?: string) {
    if (
        [
            'A solution on behalf of my client/prospect',
            'Becoming an official Sweep Partner',
            'Connecting my technology with Sweep'
        ].includes(contactRequest)
    ) {
        return srdAttribution['partner-sales'].meeting;
    }

    if (!country && !industry) {
        return srdAttribution['corporate-fr'].meeting;
    }

    if (srdAttribution['finance-fr'].industries.includes(industry)) {
        return srdAttribution['finance-fr'].meeting;
    }

    if (srdAttribution['corporate-fr'].countries.includes(country)) {
        return srdAttribution['corporate-fr'].meeting;
    }

    return srdAttribution['corporate-uk'].meeting;
}
