import { ArrowRight, CheckCircle, UserRectangle } from '@phosphor-icons/react';
import React, { useMemo, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Stack, styled, Grid, useTheme, useMediaQuery } from '@mui/material';
import { navigate } from 'gatsby';
import { HubspotForm, HubSpotFormProps } from '@lib/components/SwFormBuilder/HubspotForm';
import { usePublicIp } from '@lib/components/SwFormBuilder/ip';
import { algaeGreenStrong, grey3 } from '@lib/components/bedrock/SwColors';
import { SwPaper } from '@lib/components/bedrock/SwPaper';
import SwLine from '@lib/components/bedrock/layout/SwLine';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import SwLink from '../../components/v1/SwLink';
import SwSectionCard from '../../components/v1/SwSectionCard';
import SwButton, { SwButtonKind } from '../../lib/components/bedrock/button/SwButton';
import { getSalesLoftMeetings } from './sdr';

type SwDetailsFormProps = HubSpotFormProps;

const StylediFrame = styled('iframe')`
    border: none;
    width: 100%;
    height: 950px;
`;

const Title = styled(SwTypography)`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const GridWithPadding = styled(Grid)`
    padding: 0 ${() => (useMediaQuery(useTheme().breakpoints.down('md')) ? 0 : useTheme().spacing(4))};
    padding-top: ${() => (useMediaQuery(useTheme().breakpoints.down('md')) ? useTheme().spacing(4) : 0)};
`;
const HiddenGridOnMobile = styled(Grid)`
    display: ${() => (useMediaQuery(useTheme().breakpoints.down('md')) ? 'none' : 'block')};
`;

type ViewState = 'contactForm' | 'calendar' | 'successMessage';
type UserFlow = 'direct' | 'selectMeeting';

const SwDetailsForm: React.FunctionComponent<SwDetailsFormProps> = (props: SwDetailsFormProps) => {
    const { form, ...rest } = props;
    const { t } = useTranslation();
    const [viewState, setViewState] = useState<ViewState>('contactForm');
    const [userFlow, setUserFlow] = useState<UserFlow>('direct');
    const [sdr, setSdr] = useState<{ country?: string; industry?: string; contactRequest?: string }>({});
    const ip = usePublicIp();

    const meetingLink = useMemo<string>(
        () => (Object.keys(sdr).length ? getSalesLoftMeetings(sdr.country, sdr.industry, sdr.contactRequest) : null),
        [sdr]
    );

    return (
        <Grid container={true}>
            <Grid item={true} md={8} xs={12}>
                <SwPaper>
                    <Stack spacing={2}>
                        <Title bold={true} component={'p'} variant={'h5'}>
                            <UserRectangle size={20} /> {t('your_details')}
                        </Title>

                        {viewState === 'contactForm' && (
                            <HubspotForm
                                form={form}
                                ipAddress={ip}
                                options={{ showSuccess: false }}
                                submitRender={({ isLoading }) => (
                                    <Stack direction={'row'} gap={1} justifyContent={'end'} width={'100%'}>
                                        <SwButton
                                            kind={SwButtonKind['Secondary']}
                                            loading={isLoading}
                                            type={'submit'}
                                            onClick={() => setUserFlow('direct')}
                                        >
                                            {form.submitText ?? t('submit')}
                                        </SwButton>
                                        <SwButton
                                            endIcon={<ArrowRight />}
                                            kind={SwButtonKind.Primary}
                                            loading={isLoading}
                                            type={'submit'}
                                            onClick={() => setUserFlow('selectMeeting')}
                                        >
                                            {t('select_date_and_time')}
                                        </SwButton>
                                    </Stack>
                                )}
                                onSuccess={({ fields }) => {
                                    const country = fields.find(({ name }) => name === 'country_sf')?.value;
                                    const industry = fields.find(({ name }) => name === 'industry_sf')?.value;

                                    setSdr({
                                        country,
                                        industry,
                                        contactRequest: fields.find(
                                            ({ name }) => name === 'git___type_of_contact_request'
                                        )?.value
                                    });

                                    if (userFlow === 'selectMeeting') {
                                        setViewState('calendar');
                                    } else {
                                        setViewState('successMessage');
                                    }
                                }}
                                {...rest}
                            />
                        )}
                        {viewState === 'calendar' && Object.keys(sdr).length && (
                            <Stack alignItems={'end'} justifyContent={'end'} spacing={2}>
                                <StylediFrame
                                    frameBorder={'0'}
                                    height={'950px'}
                                    src={meetingLink}
                                    style={{ display: viewState !== 'calendar' ? 'none' : 'unset' }}
                                    width={'100%'}
                                />
                                <SwLine spacing={0} />
                                <SwButton
                                    aria-label={t('skip_this_step')}
                                    kind={SwButtonKind.Secondary}
                                    onClick={() => setViewState('successMessage')}
                                >
                                    {t('skip_this_step')}
                                </SwButton>
                            </Stack>
                        )}
                        {viewState === 'successMessage' && <SuccessMessage form={form} />}
                    </Stack>
                </SwPaper>
            </Grid>
            <GridWithPadding container={true} item={true} md={4} xs={12}>
                <Grid item={true} xs={12}>
                    <SwTypography color={grey3} variant={'body1'}>
                        <Trans i18nKey={'get_int_touch_page.sweep_introduction'}>
                            <SwLink
                                color={grey3}
                                href={'/#features'}
                                noHoverUnderlined={false}
                                noNewTab={true}
                                underlined={true}
                                variant={'body2'}
                            />
                        </Trans>
                    </SwTypography>
                </Grid>
                <HiddenGridOnMobile item={true} xs={12}>
                    <img alt={'Illustration of an antenna'} src={'https://sweep.imgix.net/get_in_touch/antenna.png'} />
                </HiddenGridOnMobile>
            </GridWithPadding>
        </Grid>
    );
};

const SuccessMessage = (form) => {
    const { t } = useTranslation();

    return (
        <Box alignItems={'start'} display={'flex'} flexDirection={'column'} gap={2}>
            <Box flex={1} width={'100%'}>
                <SwSectionCard
                    hideLine={true}
                    open={true}
                    openable={false}
                    title={
                        <Stack direction={'row'} id={'success-response'} spacing={1}>
                            <CheckCircle color={algaeGreenStrong} size={24} weight={'fill'} />
                            {form.inlineMessage ? (
                                <SwTypography
                                    component={'div'}
                                    dangerouslySetInnerHTML={{ __html: form.inlineMessage }}
                                />
                            ) : (
                                <SwTypography component={'div'}>
                                    {t('get_int_touch_page.form_message_thank_you')}
                                </SwTypography>
                            )}
                        </Stack>
                    }
                />
            </Box>
            <SwTypography color={grey3} variant={'body2'}>
                {t('get_int_touch_page.form_email_confirmation_message')}
            </SwTypography>
            <SwButton
                aria-label={'Go to Blog'}
                kind={SwButtonKind.Secondary}
                startIcon={<ArrowRight />}
                onClick={() => navigate(`/blog`)}
            >
                {t('go_to_blog')}
            </SwButton>
        </Box>
    );
};

export default SwDetailsForm;
